import * as Styled from './Dateline.styled'
import dateLineIcon from '../../../assets/icon/dateline-logo.svg'
import coverIcon from '../../../assets/icon/cover-logo.svg'
import SVG from 'react-inlinesvg'
import { FC } from 'react'
import { DatelineProps } from './Dateline.model'

export const Dateline: FC<DatelineProps> = ({ date, year, number, isColophon = false }) => {
  return (
    <Styled.Dateline isColophon={isColophon}>
      <Styled.Icon isColophon={isColophon}>
        {isColophon ? (
          <SVG src={dateLineIcon} uniquifyIDs={true} />
        ) : (
          <SVG src={coverIcon} uniquifyIDs={true} />
        )}
      </Styled.Icon>
      <Styled.PublicationData isColophon={isColophon}>
        <Styled.EditionDate>
          <Styled.Date isColophon={isColophon}>{date}</Styled.Date>
          <Styled.Year isColophon={isColophon}>{year}</Styled.Year>
          <Styled.Number isColophon={isColophon}>{number}</Styled.Number>
        </Styled.EditionDate>
        <Styled.UserName isColophon={isColophon}>@garnicio</Styled.UserName>
      </Styled.PublicationData>
    </Styled.Dateline>
  )
}
