import * as Styled from '../../styled/Magazine.styled'
import toolBadgeImage from '../assets/img/tool-badge-image-0001@3x.png'
import toolTitleImage from '../assets/img/tool-title-image-0001@3x.png'
import toolTextImage from '../assets/img/tool-text-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Paragraph, ParagraphSmall1, TextFrame } from '../../../../themes/typography.styled'

export const ToolsSidebar = () => {
  return (
    <Page color="coreSecondary400">
      <TextFrame marging="0 0 2em 0" padding="2em 2em" color="white">
        <Paragraph.Light>
          Para agregar un estilo de categoría
          <br />
          en la barra lateral.
        </Paragraph.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolBadgeImage} />
      <TextFrame marging="0 2em 2em 2em">
        <ParagraphSmall1.Light customColor={customColors.blue500}>
          Agregar un bloque de etiquetas.
        </ParagraphSmall1.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolTitleImage} />
      <TextFrame marging="0 2em 2em 2em">
        <ParagraphSmall1.Light customColor={customColors.blue500}>
          Agregar un campo de texto con encabezado.
        </ParagraphSmall1.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolTextImage} />
      <TextFrame marging="0 2em 2em 2em">
        <ParagraphSmall1.Light customColor={customColors.blue500}>
          Para agregar un campo de texto.
        </ParagraphSmall1.Light>
      </TextFrame>
    </Page>
  )
}
