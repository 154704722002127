import styled from 'styled-components'

export const ImageProfile = styled.div`
  background-color: ${(props) => props.theme.colors.secondary400};
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`

export const Upload = styled.input.attrs({
  type: 'file',
  accept: 'image/*',
})`
  cursor: pointer;
`

export const Image = styled.img`
  background-image: url(src);
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
`
