import { FC } from 'react'
import { MenuProps } from './Menu.interface'
import * as Styled from './Menu.styled'
import SVG from 'react-inlinesvg'
import boldIcon from '../../assets/icon/bold-icon.svg'
import italicIcon from '../../assets/icon/italic-icon.svg'
import listIcon from '../../assets/icon/list-icon.svg'

export const Menu: FC<MenuProps> = ({ top, left, width, isSingleLine }) => {
  const bold = () => {
    document.execCommand('bold', false)
  }

  const italic = () => {
    document.execCommand('italic', false)
  }

  const list = () => {
    const selection = window.getSelection()
    if (selection && selection.toString() !== '') {
      document.execCommand('InsertUnorderedList', false)
    }
  }
  return (
    <Styled.Menu $width={width} left={left} top={top} isSingleLine={isSingleLine}>
      <Styled.Button onClick={bold} onTouchStart={bold}>
        <SVG src={boldIcon} uniquifyIDs={true} />
      </Styled.Button>
      <Styled.Button onClick={italic} onTouchStart={italic}>
        <SVG src={italicIcon} uniquifyIDs={true} />
      </Styled.Button>
      {!isSingleLine && (
        <Styled.Button onClick={list} onTouchStart={list}>
          <SVG src={listIcon} uniquifyIDs={true} />
        </Styled.Button>
      )}
    </Styled.Menu>
  )
}
