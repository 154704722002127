import styled from 'styled-components'

export const Dateline = styled.div<{ isColophon: boolean }>`
  display: flex;
  ${(props) => !props.isColophon && 'position:absolute;bottom:1em;right:1em;z-index:2;'}
  ${(props) => props.isColophon && 'color: black;'}
  svg {
    width: 100%;
    height: 100%;
  }
`

export const Icon = styled.div<{ isColophon: boolean }>`
  ${(props) => (!props.isColophon ? `width: 2em;height: 2em;` : `width: 4em;height: 4em;`)}
  border-radius: 0.25em;
`

export const PublicationData = styled.div<{ isColophon: boolean }>`
  width: ${(props) => (!props.isColophon ? 3 : 6)}em;
  margin-top: ${(props) => (!props.isColophon ? -0.2 : -0.4)}em;
  margin-left: ${(props) => (!props.isColophon ? 0.25 : 0.5)}em;
`

export const EditionDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Date = styled.div<{ isColophon: boolean }>`
  font-family: ${(props) => props.theme.fontFamilies.displayHero};
  font-size: ${(props) => (!props.isColophon ? 0.625 : 1.25)}em;
  font-weight: 700;
  transform: rotate(-90deg);
  margin-right: -0.3754em;
`

export const Number = styled(Date)`
  margin-right: unset;
  margin-left: -0.3754em;
`

export const Year = styled.div<{ isColophon: boolean }>`
  font-family: ${(props) => props.theme.fontFamilies.displayHero};
  font-weight: 700;
  font-size: ${(props) => (!props.isColophon ? 1.55 : 3.1)}em;
`

export const UserName = styled.div<{ isColophon: boolean }>`
  font-family: ${(props) => props.theme.fontFamilies.display};
  font-weight: 700;
  font-size: ${(props) => (!props.isColophon ? 0.625 : 1.25)}em;
`
