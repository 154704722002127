import { jsPDF, HTMLFontFace } from 'jspdf'
import { Dispatch, SetStateAction } from 'react'
import { ProfileType } from '../services/Curriculum.model'

export const savePDF = (
  setIsPrintMode: Dispatch<SetStateAction<boolean>>,
  setModalIsShow: Dispatch<SetStateAction<boolean>>,
  profiles: [ProfileType] | [],
) => {
  const doc = new jsPDF('portrait', 'px', 'letter')
  const RalewayLight: HTMLFontFace = {
    family: 'Raleway',
    src: [
      {
        url: '/assets/fonts/Raleway-Light.ttf',
        format: 'truetype',
      },
    ],
    weight: 300,
  }
  const RalewayLightItalic: HTMLFontFace = {
    family: 'Raleway',
    src: [
      {
        url: '/assets/fonts/Raleway-LightItalic.ttf',
        format: 'truetype',
      },
    ],
    weight: 300,
    style: 'italic',
  }
  const RalewayBold: HTMLFontFace = {
    family: 'Raleway',
    src: [
      {
        url: '/assets/fonts/Raleway-Bold.ttf',
        format: 'truetype',
      },
    ],
    weight: 700,
  }
  const RalewayBoldItalic: HTMLFontFace = {
    family: 'Raleway',
    src: [
      {
        url: '/assets/fonts/Raleway-BoldItalic.ttf',
        format: 'truetype',
      },
    ],
    weight: 700,
    style: 'italic',
  }
  const RobotoLight: HTMLFontFace = {
    family: 'Roboto',
    src: [
      {
        url: '../../assets/fonts/Roboto-Light.ttf',
        format: 'truetype',
      },
    ],
    weight: 300,
  }
  const RobotoLightItalic: HTMLFontFace = {
    family: 'Roboto',
    src: [
      {
        url: '../../assets/fonts/Roboto-LightItalic.ttf',
        format: 'truetype',
      },
    ],
    weight: 300,
    style: 'italic',
  }
  const RobotoBold: HTMLFontFace = {
    family: 'Roboto',
    src: [
      {
        url: '../../assets/fonts/Roboto-Bold.ttf',
        format: 'truetype',
      },
    ],
    weight: 700,
  }
  const RobotoBoldItalic: HTMLFontFace = {
    family: 'Roboto',
    src: [
      {
        url: '../../assets/fonts/Roboto-BoldItalic.ttf',
        format: 'truetype',
      },
    ],
    weight: 700,
    style: 'italic',
  }
  const page0 = document.getElementById('page0')
  const page1 = document.getElementById('page1')
  const page2 = document.getElementById('page2')
  doc.html(page0 || '', {
    callback: function (doc) {
      if (page1) {
        doc.html(page1 || '', {
          callback: function (doc) {
            if (page2) {
              doc.html(page2 || '', {
                callback: function (doc) {
                  setIsPrintMode(false)
                  setModalIsShow(true)
                  window.open(doc.output('bloburl'))
                },
                x: 0,
                y: 1188,
                margin: [0, 0, 0, -18],
                width: 459,
                windowWidth: 816,
                fontFaces: [
                  RalewayLight,
                  RalewayLightItalic,
                  RalewayBold,
                  RalewayBoldItalic,
                  RobotoLight,
                  RobotoLightItalic,
                  RobotoBold,
                  RobotoBoldItalic,
                ],
              })
            } else {
              setIsPrintMode(false)
              setModalIsShow(true)
              window.open(doc.output('bloburl'))
            }
          },
          x: 0,
          y: 594,
          margin: [0, 0, 0, -18],
          width: 459,
          windowWidth: 816,
          fontFaces: [
            RalewayLight,
            RalewayLightItalic,
            RalewayBold,
            RalewayBoldItalic,
            RobotoLight,
            RobotoLightItalic,
            RobotoBold,
            RobotoBoldItalic,
          ],
        })
      } else {
        setIsPrintMode(false)
        setModalIsShow(true)
        window.open(doc.output('bloburl'))
      }
    },
    x: 0,
    y: 0,
    margin: [0, 0, 0, -18],
    width: 459,
    windowWidth: 816,
    fontFaces: [
      RalewayLight,
      RalewayLightItalic,
      RalewayBold,
      RalewayBoldItalic,
      RobotoLight,
      RobotoLightItalic,
      RobotoBold,
      RobotoBoldItalic,
    ],
  })
  let y = 11
  profiles.forEach((profile) => {
    doc.link(310, y, 140, 10, { url: `https://${profile.url}` })
    y += 18
  })
}
