import styled from 'styled-components'

export const Magazine = styled.div<{ windowHeight: number }>`
  height: ${(props) => `${props.windowHeight}px`};
  max-height: ${(props) => `${props.windowHeight}px`};
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(3rem);
  scroll-snap-type: y mandatory;
`
