import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { MagazineProps } from '../model/Magazine.model'
import { Magazine } from '../components/Magazine'
import seo from './Magazine0001.seo.json'
import {
  Cover,
  Introduction,
  Skills,
  Education,
  Interest,
  Tools,
  ToolsSidebar,
  ToolsMenu,
  IntroductionTemplate,
  HeaderProfilePicture,
  HeaderContact,
  HeaderSocialLinks,
  About,
  WorkExperience,
  WorkExperienceSummary,
  WorkExperienceContributions,
  Colophon,
} from './Pages'

export const Magazine0001: FC<MagazineProps> = ({ setModalIsShow }) => {
  useEffect(() => {
    document.body.style.backgroundColor = 'black'
  })
  return (
    <Magazine>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={seo.ogTitle} />
        <meta property="og:description" content={seo.ogDescription} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_BASE_URL}/assets/img/${seo.type}-${seo.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image:src"
          content={`${process.env.REACT_APP_BASE_URL}/assets/img/${seo.type}-${seo.slug}`}
        />
      </Helmet>
      <Cover />
      <Introduction />
      <IntroductionTemplate />
      <HeaderProfilePicture />
      <HeaderContact />
      <HeaderSocialLinks />
      <About />
      <WorkExperience />
      <WorkExperienceSummary />
      <WorkExperienceContributions />
      <Skills />
      <Education />
      <Interest />
      <Tools />
      <ToolsSidebar />
      <ToolsMenu />
      <Colophon setModalIsShow={setModalIsShow} />
    </Magazine>
  )
}
