import * as Styled from './MadeWithLoveFor.styled'
import { FC, useEffect, useState } from 'react'
import { MadeWithLoveForProps } from './MadeWithLoveFor.model'
import { shuffle } from '../../../utilities/shuffle'

export const MadeWithLoveFor: FC<MadeWithLoveForProps> = ({ name }) => {
  const [names, setNames] = useState([
    'network people',
    'graphic designer',
    'software developer',
    'data scientist',
    'marketing manager',
    'civil engineer',
    'eacher',
    'game designer',
    'art director',
    'photographer',
    'graphic artist',
  ])
  const [count, setCount] = useState(0)
  useEffect(() => {
    setNames(shuffle(names))
  }, [names])
  useEffect(() => {
    let counter = count
    const interval = setInterval(() => {
      if (counter >= names.length) {
        clearInterval(interval)
      } else {
        setCount((count) => count + 1)
        counter++
      }
    }, 70 + counter * 10)
    return () => clearInterval(interval)
  }, [count, names.length])
  return (
    <Styled.MadeWithLoveFor margin="0.3em 0 1em 0.2em">
      <Styled.SansSerif>
        <b>MADE WITH</b>
      </Styled.SansSerif>
      <Styled.Serif>
        <i> love </i>
      </Styled.Serif>
      <Styled.SansSerif>
        <b> FOR </b>
      </Styled.SansSerif>
      <Styled.Serif>
        <i> {count <= names.length - 1 ? names[count] : name}</i>
      </Styled.Serif>
    </Styled.MadeWithLoveFor>
  )
}
