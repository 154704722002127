import * as Styled from '../../styled/Magazine.styled'
import interestImage from '../assets/img/interest-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import {
  Heading5,
  Paragraph,
  ParagraphLarge1,
  ParagraphSmall1,
  TextFrame,
} from '../../../../themes/typography.styled'

export const Interest = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 2em 0 2em">
          <Heading5.Regular color="white" textAlign="center">
            INTERESES
          </Heading5.Regular>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={interestImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 0 2em">
          <Paragraph.Light>
            Opcionalmente puedes agregar una lista de intereses personales y hobbies.
          </Paragraph.Light>
        </TextFrame>
        <TextFrame marging="1em 2em 1em 2em">
          <ParagraphLarge1.Regular textAlign="center">Háblame de ti</ParagraphLarge1.Regular>
        </TextFrame>
        <TextFrame marging="1em 2em 1em 2em">
          <ParagraphSmall1.Light textAlign="center">
            ¿Cómo responderías durante la entrevista?
          </ParagraphSmall1.Light>
        </TextFrame>
        <TextFrame marging="1em 2em 1em 2em">
          <ParagraphSmall1.Light textAlign="center">
            Esta lista sirve como guía
            <br />
            se agregué al cv o no.
          </ParagraphSmall1.Light>
        </TextFrame>
        <TextFrame marging="1em 2em 2em 2em">
          <Paragraph.Light textAlign="center">
            Asegúrate de ser breve y anotarlos solo si aportan valor o coincide con la imagen que
            quieres reflejar.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
