import type { DefaultTheme } from 'styled-components'
import { colors } from './colors'
const display = 'Raleway'
const displayHero = 'Oswald'
const content = 'Roboto'
const contentSerif = 'Libre Baskerville'

export const fontFamilies: DefaultTheme['fontFamilies'] = {
  display,
  displayHero,
  content,
  contentSerif,
}

export const typography: DefaultTheme['typography'] = {
  title1: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '3.5em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title2: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '3em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title3: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '2.5em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title4: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '2em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title5: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '1.5em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title6: {
    fontFamily: display,
    fontWeight: 400,
    fontSize: '1em',
    lineHeight: '1em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  titleHero: {
    fontFamily: displayHero,
    fontWeight: 400,
    fontSize: '4.75em',
    lineHeight: '1em',
    letterSpacing: '-0.04em',
    color: colors.white,
  },
  paragraph: {
    fontFamily: content,
    fontWeight: 400,
    fontSize: '1.125em',
    lineHeight: '1.25em',
    letterSpacing: '0',
    color: colors.gray900,
  },
  subtitle: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1.25em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  subtitle2: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  subtitle3: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  paragraph1: {
    fontFamily: content,
    fontWeight: 400,
    fontSize: '.85em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  paragraph2: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.8em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  badge: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '0.75em',
    lineHeight: '1em',
    letterSpacing: '0.0025em',
    color: colors.white,
  },
  list: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.90em',
    lineHeight: '1em',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
}

export const fontTag: DefaultTheme['fontTag'] = {
  title1: 'h1',
  title2: 'h2',
  title3: 'h3',
  title4: 'h4',
  titleHero: 'h1',
  subtitle: 'p',
  subtitle2: 'p',
  subtitle3: 'p',
  paragraph: 'p',
  paragraph1: 'p',
  paragraph2: 'p',
  badge: 'p',
}
