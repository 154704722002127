import { FC, useEffect, useState } from 'react'
import * as Styled from './Icon.slyded'

import behanceIcon from '../../assets/icon/behance-icon.png'
import bloggerIcon from '../../assets/icon/blogger-icon.png'
import facebookIcon from '../../assets/icon/facebook-icon.png'
import githubIcon from '../../assets/icon/github-icon.png'
import googleIcon from '../../assets/icon/google-icon.png'
import instagramIcon from '../../assets/icon/instagram-icon.png'
import linkedinIcon from '../../assets/icon/linkedin-icon.png'
import mediumIcon from '../../assets/icon/medium-icon.png'
import pinterestIcon from '../../assets/icon/pinterest-icon.png'
import skypeIcon from '../../assets/icon/skype-icon.png'
import snapchatIcon from '../../assets/icon/snapchat-icon.png'
import stackOverflowIcon from '../../assets/icon/stack-overflow-icon.png'
import trelloIcon from '../../assets/icon/trello-icon.png'
import tumblrIcon from '../../assets/icon/tumblr-icon.png'
import twitchIcon from '../../assets/icon/twitch-icon.png'
import twitterIcon from '../../assets/icon/twitter-icon.png'
import vimeoIcon from '../../assets/icon/vimeo-icon.png'
import whatsappIcon from '../../assets/icon/whatsapp-icon.png'
import youtubeIcon from '../../assets/icon/youtube-icon.png'
import manfredIcon from '../../assets/icon/manfred-icon.png'
import kofiIcon from '../../assets/icon/kofi-icon.png'
import garnicioIcon from '../../assets/icon/garnicio-icon.png'
import telIcon from '../../assets/icon/tel-icon.png'
import mailtoIcon from '../../assets/icon/mailto-icon.png'
import linkIcon from '../../assets/icon/link-icon.png'

import { IconProps } from './Icon.model'

export const Icon: FC<IconProps> = ({ text, protocol }) => {
  const [icon, setIcon] = useState(linkIcon)
  useEffect(() => {
    if (protocol === 'mailto') {
      setIcon(mailtoIcon)
      return
    } else if (protocol === 'tel') {
      setIcon(telIcon)
      return
    } else {
      if (text.includes('behance')) {
        setIcon(behanceIcon)
        return
      }
      if (text.includes('blogger')) {
        setIcon(bloggerIcon)
        return
      }
      if (text.includes('facebook')) {
        setIcon(facebookIcon)
        return
      }
      if (text.includes('github')) {
        setIcon(githubIcon)
        return
      }
      if (text.includes('google')) {
        setIcon(googleIcon)
        return
      }
      if (text.includes('instagram')) {
        setIcon(instagramIcon)
        return
      }
      if (text.includes('linkedin')) {
        setIcon(linkedinIcon)
        return
      }
      if (text.includes('medium')) {
        setIcon(mediumIcon)
        return
      }
      if (text.includes('pinterest')) {
        setIcon(pinterestIcon)
        return
      }
      if (text.includes('skype')) {
        setIcon(skypeIcon)
        return
      }
      if (text.includes('snapchat')) {
        setIcon(snapchatIcon)
        return
      }
      if (text.includes('stackoverflow')) {
        setIcon(stackOverflowIcon)
        return
      }
      if (text.includes('trello')) {
        setIcon(trelloIcon)
        return
      }
      if (text.includes('tumblr')) {
        setIcon(tumblrIcon)
        return
      }
      if (text.includes('twitch')) {
        setIcon(twitchIcon)
        return
      }
      if (text.includes('twitter')) {
        setIcon(twitterIcon)
        return
      }
      if (text.includes('vimeo')) {
        setIcon(vimeoIcon)
        return
      }
      if (text.includes('whatsapp')) {
        setIcon(whatsappIcon)
        return
      }
      if (text.includes('youtube')) {
        setIcon(youtubeIcon)
        return
      }
      if (text.includes('mnf.red')) {
        setIcon(manfredIcon)
        return
      }
      if (text.includes('ko-fi')) {
        setIcon(kofiIcon)
        return
      }
      if (text.includes('garnicio')) {
        setIcon(garnicioIcon)
        return
      }
      setIcon(linkIcon)
    }
  }, [protocol, text])
  return <Styled.Icon src={icon} />
}
