import * as Styled from '../../styled/Magazine.styled'
import linksImage from '../assets/img/links-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Paragraph, ParagraphSmall1, TextFrame } from '../../../../themes/typography.styled'

export const HeaderSocialLinks = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <TextFrame marging="0 2em 0 2em">
          <Paragraph.Light color="white">
            Por último agregamos cualquier sitio web de redes sociales, portafolio o redes
            profesionales importantes.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Introduction>
      <Styled.IntroductionImage src={linksImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <ParagraphSmall1.Regular textAlign="center">
            En esta sección los iconos aparecen mágicamente ✨
          </ParagraphSmall1.Regular>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
