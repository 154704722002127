import * as Styled from '../../styled/Magazine.styled'
import toolAddImage from '../assets/img/tool-add-image-0001@3x.png'
import toolRemoveImage from '../assets/img/tool-remove-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import {
  Heading5,
  Paragraph,
  ParagraphSmall1,
  TextFrame,
} from '../../../../themes/typography.styled'

export const Tools = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 2em 0 2em">
          <Heading5.Regular color="white" textAlign="center">
            HERRAMIENTAS DE EDICIÓN
          </Heading5.Regular>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <TextFrame marging="0 0 2em 0" padding="2em 2em" color="white">
        <Paragraph.Light>
          La interfaz en CV Friendly cuenta con varias herramientas discretas e intuitivas que
          permiten editar el documento sin distracciones.
        </Paragraph.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolAddImage} />
      <TextFrame marging="0 2em 2em 2em">
        <ParagraphSmall1.Light customColor={customColors.blue500}>
          Para agregar una sección de experiencia laboral, una categoría en la barra lateral o una
          nueva página.
        </ParagraphSmall1.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolRemoveImage} />
      <TextFrame marging="0 2em 2em 2em">
        <ParagraphSmall1.Light customColor={customColors.blue500}>
          Elimina cualquier sección o página.
        </ParagraphSmall1.Light>
      </TextFrame>
    </Page>
  )
}
