import styled from 'styled-components'
import { StyledPageProps } from './Page.model'

export const Page = styled.div<StyledPageProps>`
  width: 100%;
  height: 100%;
  ${(props) => props.color && `background-color: ${props.theme.colors[props.color]}`};
  ${(props) => props.customColor && `background-color: ${props.customColor}`};
  ${(props) =>
    !props.color && !props.customColor && `background-color: ${props.theme.colors.white}`};
  position: relative;
  font-size: ${(props) => props.fontSize}px;
  box-shadow: rgb(0 0 0 / 40%) 0 0 6px;
  @media (min-width: 481px) {
    max-width: ${(props) => props.maxWidth}px;
  }
`

export const Screen = styled.section`
  background-color: ${(props) => props.theme.colors.coreSecondary700};
  height: 100%;
  font-size: 1.2rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
`
