import * as Styled from '../../styled/Magazine.styled'
import templateImage from '../assets/img/template-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import {
  Black,
  Paragraph,
  ParagraphLarge3,
  ParagraphSmall1,
  TextFrame,
} from '../../../../themes/typography.styled'

export const IntroductionTemplate = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <TextFrame marging="0 2em 0 2em">
          <Paragraph.Light color="white">
            La plantilla contiene datos de ejemplo y algunos consejos.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Introduction>
      <TextFrame marging="2em 2em 0 2em">
        <ParagraphLarge3.Light customColor={customColors.blue500}>
          <Black>1</Black> RELLENA EL FORMATO
        </ParagraphLarge3.Light>
      </TextFrame>
      <TextFrame marging="0 2em 0 2em">
        <ParagraphLarge3.Light textAlign="right" customColor={customColors.blue500}>
          <Black>2</Black> DESCARGA EL PDF
        </ParagraphLarge3.Light>
      </TextFrame>
      <Styled.IntroductionImage src={templateImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <ParagraphSmall1.Regular textAlign="right">
            <i>Así de fácil, ¡muy fácil!</i>
          </ParagraphSmall1.Regular>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
