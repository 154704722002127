import * as Styled from '../../styled/Magazine.styled'
import workexperience2Image from '../assets/img/work-experience-2-image-0001@3x.png'
import { Page } from '../../components/Page'
import { Italic, Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const WorkExperienceSummary = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.IntroductionImage src={workexperience2Image} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <Paragraph.Light>
            Describe en primera persona un resumen breve de todo aquello que aprendiste y aportaste
            a la empresa. Después menciona tus aspiraciones laborales;
            <br />
            Por ejemplo: “
            <Italic>
              Por ello aspiro a un puesto afín, con oportunidad de crecimiento y ser considerado
              para...
            </Italic>
            ” seguido de otros puestos en los que podrías encajar o donde te ves a mediano o largo
            plazo. En la próxima línea puedes agregar de forma opcional algunas estadísticas que
            aporten valor profesional.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
