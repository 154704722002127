import { FC } from 'react'
import * as Styled from './ImageProfile.styled'
import { ImageProps } from './ImageProfile.interface'
import Resizer from 'react-image-file-resizer'

export const Image: FC<ImageProps> = ({ onChange, src, width, height }) => {
  const Upload = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        'WEBP',
        80,
        0,
        (uri) => {
          onChange(uri as string)
        },
        'base64',
        300,
        300,
      )
    }
  }
  return (
    <Styled.ImageProfile>
      <Styled.Upload onChange={Upload} />
      <Styled.Image src={src} width={width} height={height} />
    </Styled.ImageProfile>
  )
}
