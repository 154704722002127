import * as Styled from '../../styled/Magazine.styled'
import introductionImage from '../assets/img/introduction-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import {
  Heading5,
  Paragraph,
  ParagraphSmall1,
  TextFrame,
} from '../../../../themes/typography.styled'
import { useNavigate } from 'react-router-dom'

export const Introduction = () => {
  const navigate = useNavigate()
  return (
    <Page customColor={customColors.blue100}>
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame onClick={() => navigate('/curriculum')} marging="0 2em .5em 2em">
          <Heading5.Regular color="white" textAlign="center">
            CV Friendly
          </Heading5.Regular>
        </TextFrame>
        <TextFrame marging="0 2em 0 2em">
          <Paragraph.Light color="white" textAlign="center">
            Es una moderna web app para redactar curriculums de forma rápida, amigable, gratuita y
            fácil, ¡muy fácil!
          </Paragraph.Light>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={introductionImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 0 2em">
          <Paragraph.Light>
            Hace tres semanas redacté mi CV en Google Docs y resulto todo un desafío usar tablas
            dentro de tablas para lograr un buen aspecto. Lo envié a mis amigos cercanos para que lo
            revisaran y terminaron usando el documento como plantilla XD al final del día tuvieron
            muchas dificultades y lo descuadraron todo, así que me dispuse de inmediato a tirar
            código a lo loco por dos semanas y este fue el resultado &lt;3
          </Paragraph.Light>
        </TextFrame>
        <TextFrame marging="2em 2em 2em 2em">
          <ParagraphSmall1.Light textAlign="right">
            <i>Espero les sirva, le den amor y compartan.</i>
          </ParagraphSmall1.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
