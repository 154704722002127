import * as Styled from '../../styled/Magazine.styled'
import aboutImage from '../assets/img/about-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Heading5, Italic, Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const About = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 2em 0 2em">
          <Heading5.Regular color="white" textAlign="center">
            ACERCA DE MÍ
          </Heading5.Regular>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={aboutImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <Paragraph.Light>
            Sobre mí o <Italic>About me</Italic>, como quieran llamarlo; solo escribe ahí un breve
            resumen en primera persona sobre ti tal cual te presentarías ante los compañeros de
            trabajo. Comunícale a la empresa que acaba de encontrar a la persona ideal para la
            vacante, elógiate tanto como puedas con su respaldo en la sección de experiencia
            laboral, algo de <Italic>clickbait</Italic> sería un buen remate.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
