import * as Styled from '../../styled/Magazine.styled'
import workexperienceImage from '../assets/img/work-experience-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Heading5, Italic, Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const WorkExperience = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 2em 0 2em">
          <Heading5.Regular color="white" textAlign="center">
            EXPERIENCIA LABORAL
          </Heading5.Regular>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={workexperienceImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <Paragraph.Light>
            <Italic>Work experience</Italic> o simplemente <Italic>experience</Italic> es la sección
            principal donde puedes crear una lista histórica de tu experiencia laboral, primero
            escribimos la información básica como puesto, empresa, ciudad y fechas las cuales pueden
            ser muy detalladas o simplificadas escribiendo solo los años.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
