import styled from 'styled-components'
import { ListColors, FullListColors } from './DefaultTheme.model'

export interface WithColor {
  color?: ListColors
  customColor?: never
}

export interface WithFullColor {
  color?: FullListColors
  customColor?: never
}

export interface WithCustomColor {
  color?: never
  customColor?: string
}

interface TypographyWithColor extends WithColor {
  textAlign?: 'right' | 'center' | 'justify'
}

interface TypographyWithCustomColor extends WithCustomColor {
  textAlign?: 'right' | 'center' | 'justify'
}

type TypographyProps = TypographyWithColor | TypographyWithCustomColor

interface TextFrameWithColor extends WithColor {
  marging?: string
  padding?: string
}

interface TextFrameWithCustomColor extends WithCustomColor {
  marging?: string
  padding?: string
}

type TextFrameProps = TextFrameWithColor | TextFrameWithCustomColor

export const TextFrame = styled.div<TextFrameProps>`
  ${(props) => props.color && `background-color: ${props.theme.colors[props.color]};`}
  ${(props) => props.customColor && `background-color: ${props.customColor};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.marging && `margin: ${props.marging};`}
`

const TextBase = styled.p<TypographyProps>`
  ${(props) => props.color && `color: ${props.theme.colors[props.color]};`}
  ${(props) => props.customColor && `color: ${props.customColor};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`

export const Heading1 = {
  Thin: styled(TextBase.withComponent('h1'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h1'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h1'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h1'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h1'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h1'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h1'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h1'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h1'))`
    font-weight: 900;
  `,
}

export const Heading2 = {
  Thin: styled(TextBase.withComponent('h2'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h2'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h2'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h2'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h2'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h2'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h2'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h2'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h2'))`
    font-weight: 900;
  `,
}

export const Heading3 = {
  Thin: styled(TextBase.withComponent('h3'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h3'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h3'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h3'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h3'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h3'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h3'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h3'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h3'))`
    font-weight: 900;
  `,
}

export const Heading4 = {
  Thin: styled(TextBase.withComponent('h4'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h4'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h4'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h4'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h4'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h4'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h4'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h4'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h4'))`
    font-weight: 900;
  `,
}

export const Heading5 = {
  Thin: styled(TextBase.withComponent('h5'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h5'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h5'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h5'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h5'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h5'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h5'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h5'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h5'))`
    font-weight: 900;
  `,
}

export const Heading6 = {
  Thin: styled(TextBase.withComponent('h6'))`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase.withComponent('h6'))`
    font-weight: 200;
  `,
  Light: styled(TextBase.withComponent('h6'))`
    font-weight: 300;
  `,
  Regular: styled(TextBase.withComponent('h6'))`
    font-weight: 400;
  `,
  Medium: styled(TextBase.withComponent('h6'))`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase.withComponent('h6'))`
    font-weight: 600;
  `,
  Bold: styled(TextBase.withComponent('h6'))`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase.withComponent('h6'))`
    font-weight: 800;
  `,
  Black: styled(TextBase.withComponent('h6'))`
    font-weight: 900;
  `,
}

export const ParagraphSmall1 = {
  Thin: styled(TextBase)`
    font-size: 1em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 1em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 1em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 1em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 1em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 1em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 1em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 1em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 1em;
    font-weight: 900;
  `,
}

export const ParagraphSmall2 = {
  Thin: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 0.875em;
    font-weight: 900;
  `,
}

export const ParagraphSmall3 = {
  Thin: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 0.75em;
    font-weight: 900;
  `,
}

export const ParagraphSmall4 = {
  Thin: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 0.5em;
    font-weight: 900;
  `,
}

export const Paragraph = {
  Thin: styled(TextBase)`
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-weight: 900;
  `,
}

export const ParagraphLarge1 = {
  Thin: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 1.25em;
    font-weight: 900;
  `,
}

export const ParagraphLarge2 = {
  Thin: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 1.375em;
    font-weight: 900;
  `,
}

export const ParagraphLarge3 = {
  Thin: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 1.5em;
    font-weight: 900;
  `,
}

export const ParagraphLarge4 = {
  Thin: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 100;
  `,
  ExtraLight: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 200;
  `,
  Light: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 300;
  `,
  Regular: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 400;
  `,
  Medium: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 500;
  `,
  SemiBold: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 600;
  `,
  Bold: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 700;
  `,
  ExtraBold: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 800;
  `,
  Black: styled(TextBase)`
    font-size: 1.625em;
    font-weight: 900;
  `,
}

export const XXXLarge = styled.span`
  font-size: 150%;
`

export const XXLarge = styled.span`
  font-size: 140%;
`

export const XLarge = styled.span`
  font-size: 130%;
`

export const Large = styled.span`
  font-size: 120%;
`

export const Small = styled.small`
  font-size: 80%;
`

export const XSmall = styled.span`
  font-size: 70%;
`

export const XXSmall = styled.span`
  font-size: 60%;
`

export const Thin = styled.span`
  font-weight: 100;
`

export const ExtraLight = styled.span`
  font-weight: 200;
`

export const Light = styled.span`
  font-weight: 300;
`

export const Medium = styled.span`
  font-weight: 500;
`

export const SemiBold = styled.span`
  font-weight: 600;
`

export const Bold = styled.b``

export const ExtraBold = styled.span`
  font-weight: 800;
`

export const Black = styled.span`
  font-weight: 900;
`

export const Italic = styled.i``
