import ContentEditable from 'react-contenteditable'
import styled from 'styled-components'

export const Text = styled.div`
  width: 100%;
`

export const ContentEditableStyled = styled(ContentEditable)<{
  $font: string
  $color: string
  $isSingleLine: boolean
}>`
  color: ${(props) => props.theme.colors[props.$color as keyof typeof props.theme.colors]};
  white-space: ${(props) => (props.$isSingleLine ? 'nowrap' : 'unset')};
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    cursor: pointer;
  }
  :focus {
    cursor: text;
    outline: none;
  }
  br {
    display: ${(props) => (props.$isSingleLine ? 'none' : 'inline')};
  }
  ${(props) => {
    switch (props.$font) {
      case 'paragraph':
        return `
          a {
            color: ${props.theme.typography.paragraph.color}
          }
          ul {
            list-style-type: disc;
          }
          li {
            padding: 2px;
            margin-left: 20px;
            font-family: ${props.theme.typography.list.fontFamily};
            font-weight: ${props.theme.typography.list.fontWeight};
            font-size: ${props.theme.typography.list.fontSize};
            line-height: ${props.theme.typography.list.lineHeight};
            letter-spacing: ${props.theme.typography.list.letterSpacing};
          }
        `
      case 'paragraph1':
        return `
          font-family: ${props.theme.typography.paragraph1.fontFamily};
          font-weight: ${props.theme.typography.paragraph1.fontWeight};
          font-size: ${props.theme.typography.paragraph1.fontSize};
          line-height: ${props.theme.typography.paragraph1.lineHeight};
          letter-spacing: ${props.theme.typography.paragraph1.letterSpacing};
          color: ${props.theme.typography.paragraph1.color};
        `
      case 'paragraph2':
        return `
          font-family: ${props.theme.typography.paragraph2.fontFamily};
          font-weight: ${props.theme.typography.paragraph2.fontWeight};
          font-size: ${props.theme.typography.paragraph2.fontSize};
          line-height: ${props.theme.typography.paragraph2.lineHeight};
          letter-spacing: ${props.theme.typography.paragraph2.letterSpacing};
          color: ${props.theme.typography.paragraph2.color};
        `
      case 'badge':
        return `
          font-family: ${props.theme.typography.badge.fontFamily};
          font-weight: ${props.theme.typography.badge.fontWeight};
          font-size: ${props.theme.typography.badge.fontSize};
          line-height: ${props.theme.typography.badge.lineHeight};
          letter-spacing: ${props.theme.typography.badge.letterSpacing};
          color: ${props.theme.typography.badge.color};
          ul {
            display: flex;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
          }
          li {
            background-color: ${props.theme.colors.secondary400};
            border-radius: 10px;
            padding: 0 6px;
            margin: 1.5px 1.5px;
          }
        `
      case 'title4':
        return `
          margin: 0 0 4px 0;
        `
    }
  }}
`
