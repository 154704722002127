import { FC } from 'react'
import { Text } from '../Text'
import * as Styled from './Link.styled'
import { LinkProps } from './Link.model'
import { Icon } from '../Icon'

export const Link: FC<LinkProps> = ({ protocol = 'https', text, onChange, setIsEditMode }) => {
  return (
    <Styled.Link>
      {text !== '' && <Icon protocol={protocol} text={text} />}
      <Text
        value={text}
        font="paragraph"
        onChange={onChange}
        color={'white'}
        setIsEditMode={setIsEditMode}
        isFormattable={false}
      />
    </Styled.Link>
  )
}
