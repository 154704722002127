import { useCallback, useEffect, useState } from 'react'

const useWindowSize = () => {
  const getWidth = useCallback(
    () => (window.visualViewport ? window.visualViewport.width : window.innerWidth),
    [],
  )
  const getHeight = useCallback(
    () => (window.visualViewport ? window.visualViewport.height : window.innerHeight),
    [],
  )
  const [height, setHeight] = useState<number>(getHeight())
  const [width, setWidth] = useState<number>(getWidth())

  useEffect(() => {
    const handleResize = (e: Event) => {
      setHeight(getHeight())
      setWidth(getWidth())
    }

    window.addEventListener('resize', handleResize)
    // From the top of my head this used to be required for older browsers since
    // this didn't trigger a resize event. Keeping it in to be safe.
    window.addEventListener('orientationchange', handleResize)
    // This is needed on iOS to resize the viewport when the Virtual/OnScreen
    // Keyboard opens. This does not trigger any other event, or the standard
    // resize event.
    window.visualViewport?.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
      window.visualViewport?.removeEventListener('resize', handleResize)
    }
  }, [getHeight, getWidth])

  return {
    windowWidth: width,
    windowHeight: height,
  }
}

export default useWindowSize
