import styled from 'styled-components'

export const TitleHero = styled.h1`
  font-size: ${(props) => props.theme.typography.titleHero.fontSize};
  font-family: ${(props) => props.theme.typography.titleHero.fontFamily};
  font-weight: ${(props) => props.theme.typography.titleHero.fontWeight};
  line-height: ${(props) => props.theme.typography.titleHero.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.titleHero.letterSpacing};
  color: ${(props) => props.theme.typography.titleHero.color};
  text-align: left;
`
