import type { DefaultTheme } from 'styled-components'

import { colors } from './colors'
import { typography, fontFamilies, fontTag } from './typography'

export const GarnicioTheme: DefaultTheme = {
  colors,
  typography,
  fontFamilies,
  fontTag,
}
