import * as Styled from '../../styled/Magazine.styled'
import { Classification, Dateline, Header } from '../../../../magazine'
import mainImage from '../assets/img/main-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'

export const Cover = () => {
  return (
    <Page>
      <Header />
      <Dateline date="0610" year="22" number="0001" />
      <Classification category="PROYECTO" tag="WEB APP" />
      <Styled.MainImage src={mainImage} />
      <Styled.GradientTop startColor={customColors.blue200} endColor={customColors.blue400} />
      <Styled.GradientBottom
        startColor={customColors.orange500}
        endColor={customColors.orange700}
      />
    </Page>
  )
}
