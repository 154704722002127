import styled from 'styled-components'
import SVG from 'react-inlinesvg'

export const Header = styled.div``

export const Curriculum = styled.div<{ isOnePage: boolean }>`
  height: 100%;
  background-color: ${(props) => props.theme.colors.coreSecondary400};
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  align-items: flex-start;
  overflow: scroll;
  justify-content: start;
`

export const Content = styled.div<{ overlay: boolean }>`
  overflow-y: ${(props) => (props.overlay ? 'clip' : 'unset')};
  overflow-x: visible;
  margin: 16px 32px 32px 32px;
  background-color: white;
`

export const Basic = styled.div`
  padding: 16px 45px 16px 16px;
  width: calc(70% - 120px);
`

export const ContactInformation = styled.div`
  padding: 16px 45px 16px 26px;
  width: 100%;
`

export const SocialMedia = styled.div`
  padding: 16px 16px 16px 0;
  width: 30%;
`

export const About = styled.div`
  padding: 16px 0 8px 0;
`

export const Row = styled.div`
  display: flex;
  padding: 16px 0 8px 0;
`

export const WorkExperience = styled.div`
  width: 70%;
  margin-right: 32px;
`

export const Qualifications = styled.div`
  width: 30%;
`
export const Qualification = styled.div``

export const Category = styled.div``

export const WorkTime = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0 16px 0;
`

export const Company = styled.div`
  width: 50%;
`

export const Time = styled.div`
  width: 50%;
  text-align: right;
`

export const CategoryContent = styled.div`
  margin-bottom: 8px;
`

export const AddButtonsSet = styled.div`
  display: flex;
  margin-top: -6px;
`

export const AddButton = styled.div<{ isPrintMode: boolean; color?: string }>`
  visibility: ${(props) => (props.isPrintMode ? 'hidden' : 'visible')};
  position: relative;
  border-top: 1px solid
    ${(props) =>
      props.color === 'secondary' ? props.theme.colors.secondary400 : props.theme.colors.gray100};
  cursor: pointer;
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 5px;
  ::before {
    content: '';
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) =>
      props.color === 'secondary' ? props.theme.colors.secondary400 : props.theme.colors.gray100};
    cursor: pointer;
  }
`

export const AddButtonBadge = styled(AddButton)`
  margin-top: 5px;
  ::before {
    width: 50px;
  }
  ::after {
    content: '';
    position: absolute;
    margin-top: -2.5px;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 5px;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.colors.gray300};
    cursor: pointer;
  }
`

export const AddButtonTitle = styled(AddButton)`
  margin-top: 5px;
  ::before {
    width: 50px;
  }
  ::after {
    content: '';
    position: absolute;
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 1px;
    border-width: 3px 0 2px 0;
    border-top-color: ${(props) => props.theme.colors.gray300};
    border-top-style: solid;
    border-bottom-style: dashed;
    border-bottom-color: ${(props) => props.theme.colors.gray300};
    cursor: pointer;
  }
`

export const AddButtonText = styled(AddButton)`
  margin-top: 5px;
  ::before {
    width: 50px;
  }
  ::after {
    content: '';
    position: absolute;
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 1px;
    border-width: 0 0 2px 0;
    border-bottom-style: dashed;
    border-bottom-color: ${(props) => props.theme.colors.gray300};
    cursor: pointer;
  }
`

export const AddPageButton = styled.div`
  border-left: 1px solid #536178;
  width: 1px;
  height: 1054px;
  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 60px;
    border-radius: 5px;
    background-color: #536178;
    cursor: pointer;
  }
  position: relative;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Space = styled.hr`
  border-bottom: 0;
  border-top: 1px solid ${(props) => props.theme.colors.gray400};
`

export const Remove = styled.div<{ space?: number; color?: string; isPrintMode: boolean }>`
  visibility: ${(props) => (props.isPrintMode ? 'hidden' : 'visible')};
  cursor: pointer;
  border-top: solid 3px
    ${(props) =>
      props.color === 'secondary' ? props.theme.colors.secondary400 : props.theme.colors.gray100};
  width: 10px;
  height: 15px;
  float: left;
  margin-left: ${(props) => `-${props.space ? 20 * props.space : 20}px`};
  margin-right: 10px;
`

export const Work = styled.div`
  padding-left: 2px;
`

export const WorkTitle = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 2px;
  align-items: center;
`

export const Bullet = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  border-radius: 6px;
  margin-right: 4px;
  margin-left: -2px;
  border: solid 2px ${(props) => props.theme.colors.gray500};
`

export const TimeLine = styled.div`
  border-left: solid 1px ${(props) => props.theme.colors.gray400};
  padding-left: 8px;
  margin-left: 4px;
`

export const Tools = styled.div`
  display: flex;
  height: 1054px;
  align-items: center;
`
export const Download = styled(SVG)`
  cursor: pointer;
  width: 80px;
  height: 80px;
  & path {
    fill: ${(props) => props.theme.colors.primary400};
  }
  margin: 32px;
  min-width: 80px;
  border-radius: 50%;
  background-color: none;
  box-shadow: inset 0px -2px 4px 0px rgba(70, 105, 156, 0.5);
  :hover {
    transform: translateY(5px);
    box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }
  :active {
    transform: translateY(5px);
    box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }
`

export const Links = styled.div`
  padding: 3px 0;
`
