import * as Styled from '../../styled/Magazine.styled'
import contributionsImage from '../assets/img/contributions-image-0001@3x.png'
import { Page } from '../../components/Page'
import { Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const WorkExperienceContributions = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.IntroductionImage src={contributionsImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <Paragraph.Light>
            Escribe un breve resumen de tus contribuciones, desafíos y los momentos más
            gratificantes.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
