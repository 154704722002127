import { FC } from 'react'
import { ClassificationProps } from './Classification.model'
import * as Styled from './Classification.styled'

export const Classification: FC<ClassificationProps> = ({ category, tag }) => {
  return (
    <Styled.Classification>
      <Styled.Category>
        <b>{category}</b>
      </Styled.Category>
      <Styled.Tag>
        <i>{tag}</i>
      </Styled.Tag>
    </Styled.Classification>
  )
}
