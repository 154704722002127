import './App.css'
import { Curriculum } from './pages/Curriculum'

import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './GlobalStyle'
import { useEffect, useState } from 'react'
import { Home } from './pages/Home'
import { Magazine0001 } from './pages/Magazines/Magazine0001'
import { GarnicioTheme, CurriculumTheme, MagazinesTheme } from './themes'
import * as Styled from './App.styled'
import { noTrack } from './utilities/noTrack'

function App() {
  const [theme, setTheme] = useState(GarnicioTheme)
  const [searchParams, setSearchParams] = useSearchParams()
  const [modalIsShow, setModalIsShow] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (modalIsShow) {
      document.body.style.overflow = 'hidden'
      const scrollPosition = window.pageYOffset
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.width = '100%'
    } else {
      document.body.removeAttribute('style')
    }
  }, [modalIsShow])
  useEffect(() => {
    if (searchParams.has('notrack')) {
      const noTrackQueryParam = searchParams.get('notrack')
      if (noTrackQueryParam) {
        noTrack()
        searchParams.delete('notrack')
        setSearchParams(searchParams)
      }
    }
    if (searchParams.has('page') && searchParams.has('type')) {
      const page = searchParams.get('page')
      const type = searchParams.get('type')
      searchParams.delete('page')
      searchParams.delete('type')
      console.log({
        pathname: `${type === 'revista' ? 'revistas/' : ''}${page}`,
        search: `?${searchParams}`,
      })
      navigate({
        pathname: `${type === 'revista' ? 'revistas/' : ''}${page}`,
        search: `?${searchParams}`,
      })
    }
  })
  useEffect(() => {
    switch (pathname.split('/')[1]) {
      case 'curriculum':
        setTheme(CurriculumTheme)
        break
      case 'revistas':
        setTheme(MagazinesTheme)
        break
    }
  }, [pathname])
  return (
    <ThemeProvider theme={theme}>
      {modalIsShow && (
        <Styled.Modal onClick={() => setModalIsShow(false)}>
          <Styled.KoFi
            src="https://ko-fi.com/garnicio/?hidefeed=true&widget=true&embed=true&preview=true"
            height="712"
            title="garnicio"
          />
          <Styled.CupHandle />
        </Styled.Modal>
      )}
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/curriculum" element={<Curriculum setModalIsShow={setModalIsShow} />} />
        <Route
          path="/revistas/cv-friendly"
          element={<Magazine0001 setModalIsShow={setModalIsShow} />}
        />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
