import styled from 'styled-components'

export const Page = styled.div`
  background-color: #ffffff;
  width: 816px;
  min-width: 816px;
  height: 1054px;
  min-height: 1054px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  margin: 0 32px 0 32px;
  display: flex;
  flex-direction: column;
  float: left;
  /* position: relative; */
`
