import * as Styled from '../../styled/Magazine.styled'
import kofiImage from '../assets/img/kofi-button@3x.png'
import { Page } from '../../components/Page'
import { ParagraphSmall3, TextFrame } from '../../../../themes/typography.styled'
import { Dateline } from '../../../../magazine'
import { Dispatch, FC, SetStateAction } from 'react'

interface ColophonProps {
  setModalIsShow: Dispatch<SetStateAction<boolean>>
}

export const Colophon: FC<ColophonProps> = ({ setModalIsShow }) => {
  return (
    <Page>
      <Styled.Colophon onClick={() => setModalIsShow(true)}>
        <Dateline date="0610" year="22" number="0001" isColophon={true} />
        <TextFrame marging="1em 7em 1em 7em" color="white">
          <Styled.IntroductionImage src={kofiImage} />
        </TextFrame>
        <TextFrame marging="0 5em 1em 5em">
          <ParagraphSmall3.Light textAlign="center">
            Octubre 2022, Garnicio Magazine es un blog con aspecto de revista con artículos y
            recursos fascinantes sobre desarrollo web, diseño, proyectos y herramientas ingeniosas y
            útiles.
          </ParagraphSmall3.Light>
        </TextFrame>
      </Styled.Colophon>
    </Page>
  )
}
