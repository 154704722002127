import styled from 'styled-components'

export const Classification = styled.div`
  position: absolute;
  bottom: 1em;
  left: 1em;
  z-index: 2;
  text-align: left;
`
export const Category = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.display};
  /* font-weight: 700; */
  font-size: 1.125em;
`

export const Tag = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.display};
  //font-style: italic;
  font-size: 0.875em;
`
