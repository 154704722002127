import * as Styled from './Page.styled'
import { FC, useRef } from 'react'
import useFontSize from '../../../../hooks/useFontSize'
import { PageProps } from './Page.model'

export const Page: FC<PageProps> = ({ children, color, customColor }) => {
  const pageRef = useRef<HTMLDivElement>(null)
  const { fontSize, maxWidth } = useFontSize(pageRef)
  return (
    <Styled.Screen>
      <Styled.Page
        fontSize={fontSize}
        maxWidth={maxWidth}
        ref={pageRef}
        {...(color && !customColor ? { color } : !color && customColor ? { customColor } : {})}
      >
        {children}
      </Styled.Page>
    </Styled.Screen>
  )
}
