import styled from 'styled-components'

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

export const KoFi = styled.iframe`
  border: none;
  border-radius: 200px 200px 150px 150px;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 5;
`

export const CupHandle = styled.div`
  width: 300px;
  height: 300px;
  border-style: solid;
  border-width: 80px 50px 50px 80px;
  border-color: white;
  border-radius: 50%;
  margin-left: -200px;
  margin-top: -50px;
  animation-name: appear;
  animation-duration: 1s;
  opacity: 2;
  @keyframes appear {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
