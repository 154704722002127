import styled from 'styled-components'

export const Header = styled.div<{ margin: string }>`
  cursor: pointer;
  margin: ${(props) => props.margin};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`
