export const getIcon = (icon: string) => {
  const icons = [
    'behance',
    'blogger',
    'facebook',
    'github',
    'google',
    'instagram',
    'linkedin',
    'medium',
    'pinterest',
    'skype',
    'snapchat',
    'stackoverflow',
    'trello',
    'tumblr',
    'twitch',
    'twitter',
    'vimeo',
    'whatsapp',
    'youtube',
    'mnf.red',
    'ko-fi',
    'garnicio',
  ]
  const result = containsAny(icon, icons)
  if (result) {
    return result
  } else {
    return 'link'
  }
}

export const containsAny = (str: string, substrings: string[]) => {
  for (let i = 0; i !== substrings.length; i++) {
    let substring = substrings[i]
    if (str.indexOf(substring) !== -1) {
      return substring
    }
  }
  return null
}
