import styled from 'styled-components'

export const Main = styled.div`
  background-color: ${(props) => props.theme.colors.gray050};
  height: 100%;
`
export const Home = styled.div`
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const Menu = styled.div`
  border-radius: 0 0 30px 30px;
  background-color: ${(props) => props.theme.colors.gray100};
  box-shadow: rgba(176, 186, 197, 0.5) 0px 0.5em 0.5em -0.5em;
  padding: 1em;
`

export const Magazine = styled.img`
  cursor: pointer;
  border-radius: 0 0.25em 0.25em 0;
  margin-left: 1em;
  box-shadow: rgba(176, 186, 197, 0.3) 2px 2px 3px 2px;
`
