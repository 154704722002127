import { Page } from '../../components/Page'
import * as Styled from './Curriculum.styled'
import { Profile } from '../../components/Profile'
import { Image } from '../../components/ImageProfile'
import { Text } from '../../components/Text'
import { Helmet } from 'react-helmet'
import { FC, useEffect, useState } from 'react'
import { Link } from '../../components/Link'
import { getCurriculum, setCurriculum } from '../../services/curriculum.service'
import {
  Category,
  PageType,
  ProfileType,
  Qualification,
  Work,
} from '../../services/Curriculum.model'
import downloadIcon from '../../assets/icon/download.svg'
import { savePDF } from '../../utilities/savePDF'
import { getIcon } from '../../components/Icon/Icon.utilities'
import seo from './Curriculum.seo.json'
import { CurriculumProps } from './Curriculum.model'

export const Curriculum: FC<CurriculumProps> = ({ setModalIsShow }) => {
  const curriculum = getCurriculum()
  const [basic, setBasic] = useState(curriculum.basic)
  const [profiles, setProfiles] = useState(curriculum.profiles)
  const [aboutTitle, setAboutTitle] = useState(curriculum.aboutTitle)
  const [about, setAbout] = useState(curriculum.about)
  const [workTitle, setWorkTitle] = useState(curriculum.workTitle)
  const [pages, setPages] = useState(curriculum.pages)
  const [isEditMode, setIsEditMode] = useState(true)
  const [isPrintMode, setIsPrintMode] = useState(false)

  const onChangeAboutTitle = (aboutTitle: string) => {
    setAboutTitle(aboutTitle)
  }

  const onChangeAbout = (about: string) => {
    setAbout(about)
  }

  const onChangeWorkExperiencetitle = (workExperiencetitle: string) => {
    setWorkTitle(workExperiencetitle)
  }
  useEffect(() => {
    setCurriculum({
      profiles,
      basic: basic,
      aboutTitle,
      about,
      workTitle,
      pages,
    })
  }, [about, aboutTitle, basic, pages, profiles, workTitle])

  return (
    <Styled.Curriculum isOnePage={pages.length === 1}>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={seo.ogTitle} />
        <meta property="og:description" content={seo.ogDescription} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_BASE_URL}/assets/img/${seo.type}-${seo.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image:src"
          content={`${process.env.REACT_APP_BASE_URL}/assets/img/${seo.type}-${seo.slug}`}
        />
      </Helmet>
      {pages.map((page, pageIndex) => (
        <Page id={`page${pageIndex}`} key={page.id}>
          {pageIndex === 0 && (
            <Styled.Header>
              <Profile>
                <Image
                  onChange={(value) => {
                    setBasic({
                      ...basic,
                      profilePicture: value,
                    })
                  }}
                  width={150}
                  height={150}
                  src={basic.profilePicture}
                />
                <Styled.Basic>
                  <Text
                    value={basic.name}
                    font="title1"
                    color="white"
                    onChange={(value) => {
                      setBasic({
                        ...basic,
                        name: value,
                      })
                    }}
                    setIsEditMode={setIsEditMode}
                  />
                  <Text
                    value={basic.position}
                    font="paragraph"
                    color="white"
                    onChange={(value) => {
                      setBasic({
                        ...basic,
                        position: value,
                      })
                    }}
                    setIsEditMode={setIsEditMode}
                  />
                  <Styled.ContactInformation>
                    <Link
                      protocol="mailto"
                      text={basic.contactForms.email}
                      onChange={(value) => {
                        setBasic({
                          ...basic,
                          contactForms: {
                            email: value,
                            phone: basic.contactForms.phone,
                          },
                        })
                      }}
                      setIsEditMode={setIsEditMode}
                    />
                    <Link
                      protocol="tel"
                      text={basic.contactForms.phone}
                      onChange={(value) => {
                        setBasic({
                          ...basic,
                          contactForms: {
                            email: basic.contactForms.email,
                            phone: value,
                          },
                        })
                      }}
                      setIsEditMode={setIsEditMode}
                    />
                  </Styled.ContactInformation>
                </Styled.Basic>
                <Styled.SocialMedia>
                  {profiles.map((profile) => (
                    <Styled.Links key={profile.id}>
                      {isEditMode && (
                        <Styled.Remove
                          isPrintMode={isPrintMode}
                          color="secondary"
                          space={2}
                          onClick={() => {
                            const newProfiles = profiles.filter(
                              (item) => item.id !== profile.id,
                            ) as [ProfileType]
                            setProfiles([...newProfiles])
                          }}
                        />
                      )}
                      <Link
                        text={profile.url}
                        onChange={(value) => {
                          profile.url = value
                          profile.icon = getIcon(value)
                          setProfiles([...profiles])
                        }}
                        setIsEditMode={setIsEditMode}
                      />
                    </Styled.Links>
                  ))}
                  {profiles.length < 4 && (
                    <Styled.AddButton
                      color="secondary"
                      isPrintMode={isPrintMode}
                      onClick={() => {
                        const newProfile = {
                          id: `${Math.floor(Date.now() / 1000)}`,
                          url: 'example.com',
                          icon: 'link',
                        }
                        const indexProfiles = profiles.length | 0
                        profiles[indexProfiles] = newProfile
                        setProfiles([...profiles])
                      }}
                    />
                  )}
                </Styled.SocialMedia>
              </Profile>
            </Styled.Header>
          )}
          <Styled.Content overlay={isEditMode}>
            {pageIndex === 0 && (
              <Styled.About>
                <Text
                  value={aboutTitle}
                  font="title2"
                  onChange={onChangeAboutTitle}
                  setIsEditMode={setIsEditMode}
                />
                <Styled.Space />
                <Text
                  value={about}
                  font="paragraph"
                  isSingleLine={false}
                  onChange={onChangeAbout}
                  setIsEditMode={setIsEditMode}
                />
              </Styled.About>
            )}
            <Styled.Row>
              <Styled.WorkExperience>
                {pageIndex === 0 && (
                  <Text
                    value={workTitle}
                    font="title2"
                    onChange={onChangeWorkExperiencetitle}
                    setIsEditMode={setIsEditMode}
                  />
                )}
                {pageIndex === 0 && <Styled.Space />}
                {page.works.map((work) => (
                  <Styled.Work key={work.id}>
                    <Styled.WorkTitle>
                      {isEditMode && (
                        <Styled.Remove
                          isPrintMode={isPrintMode}
                          onClick={() => {
                            const newWorks = page.works.filter((item) => item.id !== work.id) as [
                              Work,
                            ]
                            page.works = newWorks
                            setPages([...pages])
                          }}
                        />
                      )}
                      <Styled.Bullet></Styled.Bullet>
                      <Text
                        value={work.title}
                        font="title3"
                        onChange={(value) => {
                          work.title = value
                          setPages([...pages])
                        }}
                        setIsEditMode={setIsEditMode}
                      />
                    </Styled.WorkTitle>
                    <Styled.TimeLine>
                      <Styled.WorkTime>
                        <Styled.Company>
                          <Text
                            value={work.company}
                            font="paragraph1"
                            onChange={(value) => {
                              work.company = value
                              setPages([...pages])
                            }}
                            setIsEditMode={setIsEditMode}
                          />
                        </Styled.Company>
                        <Styled.Time>
                          <Text
                            value={work.time}
                            font="paragraph1"
                            onChange={(value) => {
                              work.time = value
                              setPages([...pages])
                            }}
                            setIsEditMode={setIsEditMode}
                          />
                        </Styled.Time>
                      </Styled.WorkTime>
                      <Text
                        value={work.content}
                        font="paragraph"
                        isSingleLine={false}
                        onChange={(value) => {
                          work.content = value
                          setPages([...pages])
                        }}
                        setIsEditMode={setIsEditMode}
                      />
                    </Styled.TimeLine>
                  </Styled.Work>
                ))}
                <Styled.AddButton
                  isPrintMode={isPrintMode}
                  onClick={() => {
                    const newWork: Work = {
                      id: `${Math.floor(Date.now() / 1000)}`,
                      title: 'Puesto',
                      company: 'Empresa - Ciudad',
                      time: 'Marzo 2021 - Agosto 2022',
                      content: 'Escribe un breve resumen.',
                    }
                    const indexNewWork = page.works.length | 0
                    page.works[indexNewWork] = newWork
                    setPages([...pages])
                  }}
                />
              </Styled.WorkExperience>
              <Styled.Qualifications>
                {page.qualifications.map((qualification) => (
                  <Styled.Qualification key={qualification.id}>
                    {isEditMode && (
                      <Styled.Remove
                        isPrintMode={isPrintMode}
                        onClick={() => {
                          const newQualifications = page.qualifications.filter(
                            (item) => item.id !== qualification.id,
                          ) as [Qualification]
                          page.qualifications = newQualifications
                          setPages([...pages])
                        }}
                      />
                    )}
                    <Text
                      value={qualification.title}
                      font="title2"
                      isSingleLine={false}
                      onChange={(value) => {
                        qualification.title = value
                        setPages([...pages])
                      }}
                      setIsEditMode={setIsEditMode}
                    />
                    <Styled.Space />
                    {qualification.categories.map((category) => (
                      <Styled.Category key={category.id}>
                        {isEditMode && (
                          <Styled.Remove
                            isPrintMode={isPrintMode}
                            onClick={() => {
                              const newCategories = qualification.categories.filter(
                                (item) => item.id !== category.id,
                              ) as [Category]
                              qualification.categories = newCategories
                              setPages([...pages])
                            }}
                          />
                        )}
                        {category.title && (
                          <Text
                            value={category.title}
                            font="title4"
                            isSingleLine={false}
                            onChange={(value) => {
                              category.title = value
                              setPages([...pages])
                            }}
                            setIsEditMode={setIsEditMode}
                          />
                        )}
                        <Styled.CategoryContent>
                          <Text
                            value={category.content}
                            font={category.type}
                            isSingleLine={false}
                            onChange={(value) => {
                              category.content = value
                              setPages([...pages])
                            }}
                            setIsEditMode={setIsEditMode}
                          />
                        </Styled.CategoryContent>
                      </Styled.Category>
                    ))}
                    <Styled.AddButtonsSet>
                      <Styled.AddButtonBadge
                        isPrintMode={isPrintMode}
                        onClick={() => {
                          const newCategory: Category = {
                            id: `${Math.floor(Date.now() / 1000)}`,
                            type: 'badge',
                            title: 'Categoría',
                            content: '<ul><li>Texto</li></ul>',
                          }
                          const indexNewCategory = qualification.categories.length | 0
                          qualification.categories[indexNewCategory] = newCategory
                          setPages([...pages])
                        }}
                      />
                      <Styled.AddButtonTitle
                        isPrintMode={isPrintMode}
                        onClick={() => {
                          const newCategory: Category = {
                            id: `${Math.floor(Date.now() / 1000)}`,
                            type: 'paragraph2',
                            title: 'Categoría',
                            content: 'Texto',
                          }
                          const indexNewCategory = qualification.categories.length | 0
                          qualification.categories[indexNewCategory] = newCategory
                          setPages([...pages])
                        }}
                      />
                      <Styled.AddButtonText
                        isPrintMode={isPrintMode}
                        onClick={() => {
                          const newCategory: Category = {
                            id: `${Math.floor(Date.now() / 1000)}`,
                            type: 'paragraph2',
                            content: 'Texto',
                          }
                          const indexNewCategory = qualification.categories.length | 0
                          qualification.categories[indexNewCategory] = newCategory
                          setPages([...pages])
                        }}
                      />
                    </Styled.AddButtonsSet>
                  </Styled.Qualification>
                ))}
                <Styled.AddButton
                  isPrintMode={isPrintMode}
                  onClick={() => {
                    const newQualification: Qualification = {
                      id: `${Math.floor(Date.now() / 1000)}`,
                      title: 'Cualificación',
                      categories: [],
                    }
                    const indexNewQualifications = page.qualifications.length | 0
                    page.qualifications[indexNewQualifications] = newQualification
                    setPages([...pages])
                  }}
                />
              </Styled.Qualifications>
            </Styled.Row>
          </Styled.Content>
        </Page>
      ))}
      {pages.length <= 2 && ( // Max 3 pages
        <Styled.AddPageButton
          onClick={() => {
            const newPage: PageType = {
              id: `${Math.floor(Date.now() / 1000)}`,
              works: [],
              qualifications: [],
            }
            const index = pages.length | 0
            pages[index] = newPage
            setPages([...pages])
          }}
        />
      )}
      <Styled.Tools>
        <Styled.Download
          src={downloadIcon}
          onClick={() => {
            setIsPrintMode(true)
            savePDF(setIsPrintMode, setModalIsShow, profiles)
          }}
        />
      </Styled.Tools>
    </Styled.Curriculum>
  )
}
