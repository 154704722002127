export const neutralColors = {
  white: '#ffffff',
  gray050: '#f4f7fa',
  gray100: '#ebeef4',
  gray200: '#dfe6ee',
  gray300: '#b0bac5',
  gray400: '#9dadc1',
  gray500: '#8091a5',
  gray600: '#667587',
  gray700: '#3f4a56',
  gray800: '#313541',
  gray900: '#16181e',
  black: '#000000',
}
// https://hihayk.github.io/scale/#5/4/46/79/1/26/7/9/0AFFEF/10/255/239/black
export const corePrimaryColors = {
  corePrimary050: '#caffe5',
  corePrimary100: '#99ffd9',
  corePrimary200: '#68ffd6',
  corePrimary300: '#39ffde',
  corePrimary400: '#0Affef',
  corePrimary500: '#07e8da',
  corePrimary600: '#05d0c5',
  corePrimary700: '#03b9af',
  corePrimary800: '#01a19a',
  corePrimary900: '#008a84',
}
// https://hihayk.github.io/scale/#5/4/63/37/-15/11/100/41/243441/233/190/252/black
export const coreSecondaryColors = {
  coreSecondary050: '#6c8790',
  coreSecondary100: '#59727e',
  coreSecondary200: '#475d6a',
  coreSecondary300: '#354956',
  coreSecondary400: '#243441',
  coreSecondary500: '#1e2c3a',
  coreSecondary600: '#192532',
  coreSecondary700: '#141d2b',
  coreSecondary800: '#101623',
  coreSecondary900: '#0b101a',
}
