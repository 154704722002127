import { Heading5, TextFrame } from '../../themes/typography.styled'
import * as Styled from './Home.styled'
import SVG from 'react-inlinesvg'
import logo from '../../assets/icon/garnicio.svg'
import magazine0001 from '../../assets/img/magazine-0001@3x.png'
import project0001 from '../../assets/img/project-0001@3x.png'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
  const navigate = useNavigate()
  return (
    <Styled.Main>
      <Styled.Home>
        <Styled.Menu>
          <SVG src={logo} />
        </Styled.Menu>
        <TextFrame marging="2em 2em 2em 1em">
          <Heading5.Light color="gray600">PROYECTOS</Heading5.Light>
        </TextFrame>
        <Styled.Magazine
          onClick={() => navigate('/curriculum')}
          src={project0001}
          width={117}
          height={150}
        />
        <TextFrame marging="2em 2em 2em 1em">
          <Heading5.Light color="gray600">REVISTAS</Heading5.Light>
        </TextFrame>
        <Styled.Magazine
          onClick={() => navigate('/revistas/cv-friendly')}
          src={magazine0001}
          width={117}
          height={200}
        />
      </Styled.Home>
    </Styled.Main>
  )
}
