import styled from 'styled-components'

export const MadeWithLoveFor = styled.p<{ margin: string }>`
  color: ${(props) => props.theme.colors.white};
  text-align: left;
  margin: ${(props) => props.margin};
  font-size: 1em;
`

export const SansSerif = styled.span`
  font-family: ${(props) => props.theme.fontFamilies.display};
`

export const Serif = styled.span`
  font-family: ${(props) => props.theme.fontFamilies.contentSerif};
  font-weight: 400;
  letter-spacing: -0.005em;
  color: #14b3d0;
`
