import { RefObject, useEffect, useState } from 'react'
import useWindowSize from './useWindowSize'

const useFontSize = (ref: RefObject<HTMLDivElement>) => {
  const { windowWidth, windowHeight } = useWindowSize()
  const [pageWidth, setPageWidth] = useState(0)
  useEffect(() => {
    setPageWidth(ref.current?.clientWidth || 0)
  }, [windowWidth, windowHeight, ref])

  return {
    fontSize: ((pageWidth * 100) / 390 / 100) * 16,
    maxWidth: windowHeight * 0.58,
  }
}

export default useFontSize
