import styled from 'styled-components'

export const Menu = styled.div<{
  left: number
  top: number
  $width: number
  isSingleLine: boolean
}>`
  top: ${(props) => `calc(${props.top}px - 50px + ${window.scrollY}px)`};
  cursor: pointer;
  left: ${(props) =>
    `calc(${props.left}px - ${props.isSingleLine ? '41px' : '60px'} + (${props.$width}px / 2))`};
  position: absolute;
  width: ${(props) => (props.isSingleLine ? '82px' : '120px')};
  height: 40px;
  border-radius: 10px;
  background-color: black;
  padding: 0 0;
  ::before {
    position: absolute;
    content: '';
    border-top: 10px solid black;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: ${(props) => (props.isSingleLine ? '31px' : '50px')};
    top: 40px;
  }
`

export const Button = styled.button`
  border: 0;
  background-color: unset;
  display: inline-block;
  width: 40px;
  height: 40px;
  color: white;
  padding: 0;
`
