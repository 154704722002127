import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  u, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  html, body, #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /*
  * Global theming styles
  */
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${(props) => props.theme.fontFamilies.content};
    color: ${(props) => props.theme.colors.black};
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6, p {
    b {
      font-weight: 700;
      i {
        font-style: italic;
      }
    }
    i {
      font-style: italic;
      b {
        font-weight: 700;
      }
    }
  }
  h1 {
    font-family: ${(props) => props.theme.typography.title1.fontFamily};
    font-weight: ${(props) => props.theme.typography.title1.fontWeight};
    font-size: ${(props) => props.theme.typography.title1.fontSize};
    line-height: ${(props) => props.theme.typography.title1.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title1.letterSpacing};
    color: ${(props) => props.theme.typography.title1.color};
  }

  h2 {
    font-family: ${(props) => props.theme.typography.title2.fontFamily};
    font-weight: ${(props) => props.theme.typography.title2.fontWeight};
    font-size: ${(props) => props.theme.typography.title2.fontSize};
    line-height: ${(props) => props.theme.typography.title2.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title2.letterSpacing};
    color: ${(props) => props.theme.typography.title2.color};
  }

  h3 {
    font-family: ${(props) => props.theme.typography.title3.fontFamily};
    font-weight: ${(props) => props.theme.typography.title3.fontWeight};
    font-size: ${(props) => props.theme.typography.title3.fontSize};
    line-height: ${(props) => props.theme.typography.title3.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title3.letterSpacing};
    color: ${(props) => props.theme.typography.title3.color};
  }

  h4 {
    font-family: ${(props) => props.theme.typography.title4.fontFamily};
    font-weight: ${(props) => props.theme.typography.title4.fontWeight};
    font-size: ${(props) => props.theme.typography.title4.fontSize};
    line-height: ${(props) => props.theme.typography.title4.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title4.letterSpacing};
    color: ${(props) => props.theme.typography.title4.color};
  }

  h5 {
    font-family: ${(props) => props.theme.typography.title5.fontFamily};
    font-weight: ${(props) => props.theme.typography.title5.fontWeight};
    font-size: ${(props) => props.theme.typography.title5.fontSize};
    line-height: ${(props) => props.theme.typography.title5.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title5.letterSpacing};
    color: ${(props) => props.theme.typography.title5.color};
  }

  h6 {
    font-family: ${(props) => props.theme.typography.title6.fontFamily};
    font-weight: ${(props) => props.theme.typography.title6.fontWeight};
    font-size: ${(props) => props.theme.typography.title6.fontSize};
    line-height: ${(props) => props.theme.typography.title6.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title6.letterSpacing};
    color: ${(props) => props.theme.typography.title6.color};
  }

  p {
    font-family: ${(props) => props.theme.typography.paragraph.fontFamily};
    font-weight: ${(props) => props.theme.typography.paragraph.fontWeight};
    font-size: ${(props) => props.theme.typography.paragraph.fontSize};
    line-height: ${(props) => props.theme.typography.paragraph.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.paragraph.letterSpacing};
    color: ${(props) => props.theme.typography.paragraph.color};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px white inset !important;
  }
`
