import { DefaultTheme } from 'styled-components'
import { neutralColors, corePrimaryColors, coreSecondaryColors } from '../colors'

export const primaryColors = {
  primary050: corePrimaryColors.corePrimary050,
  primary100: corePrimaryColors.corePrimary100,
  primary200: corePrimaryColors.corePrimary200,
  primary300: corePrimaryColors.corePrimary300,
  primary400: corePrimaryColors.corePrimary400,
  primary500: corePrimaryColors.corePrimary500,
  primary600: corePrimaryColors.corePrimary600,
  primary700: corePrimaryColors.corePrimary700,
  primary800: corePrimaryColors.corePrimary800,
  primary900: corePrimaryColors.corePrimary900,
}

export const secondaryColors = {
  secondary050: coreSecondaryColors.coreSecondary050,
  secondary100: coreSecondaryColors.coreSecondary100,
  secondary200: coreSecondaryColors.coreSecondary200,
  secondary300: coreSecondaryColors.coreSecondary300,
  secondary400: coreSecondaryColors.coreSecondary400,
  secondary500: coreSecondaryColors.coreSecondary500,
  secondary600: coreSecondaryColors.coreSecondary600,
  secondary700: coreSecondaryColors.coreSecondary700,
  secondary800: coreSecondaryColors.coreSecondary800,
  secondary900: coreSecondaryColors.coreSecondary900,
}

export const colors: DefaultTheme['colors'] = {
  ...primaryColors,
  ...secondaryColors,
  ...neutralColors,
}
