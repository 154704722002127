import { acceptsColor } from '../../../utilities/acceptsColor'

export const customColors = {
  orange050: acceptsColor('#fce9e6'),
  orange100: acceptsColor('#ffcdb8'),
  orange200: acceptsColor('#ffad8b'),
  orange300: acceptsColor('#ff8d5b'),
  orange400: acceptsColor('#ff7434'),
  orange500: acceptsColor('#ff5e00'), // Principal
  orange600: acceptsColor('#f45700'),
  orange700: acceptsColor('#e65000'),
  orange800: acceptsColor('#d94900'),
  orange900: acceptsColor('#c13a00'),
  blue050: acceptsColor('#e2f6fb'),
  blue100: acceptsColor('#b6eaf4'),
  blue200: acceptsColor('#89dced'), // Principal
  blue300: acceptsColor('#5ecee4'),
  blue400: acceptsColor('#41c4de'),
  blue500: acceptsColor('#34bad7'),
  blue600: acceptsColor('#2faac4'),
  blue700: acceptsColor('#2995aa'),
  blue800: acceptsColor('#238191'),
  blue900: acceptsColor('#185f66'),
}
