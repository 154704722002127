import styled from 'styled-components'

export const GradientTop = styled.div<{ startColor: string; endColor: string }>`
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    165deg,
    ${(props) => props.startColor} 6%,
    ${(props) => props.endColor} 100%
  );
`

export const GradientBottom = styled.div<{ startColor: string; endColor: string }>`
  width: 100%;
  height: 10em;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-image: linear-gradient(
    165deg,
    ${(props) => props.startColor} 6%,
    ${(props) => props.endColor} 100%
  );
`

export const MainImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 5em;
  z-index: 2;
  width: 100%;
`

export const IntroductionImage = styled.img`
  width: 100%;
`

export const Introduction = styled.div<{ startColor: string; endColor: string }>`
  background-image: linear-gradient(
    195deg,
    ${(props) => props.startColor} 6%,
    ${(props) => props.endColor} 100%
  );
  padding: 1em 0;
`

export const HorizontalRule = styled.hr<{ marging: string }>`
  border-width: 0;
  border-top: 1px solid ${(props) => props.theme.colors.white};
  margin: ${(props) => props.marging};
`

export const Content = styled.div`
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  z-index: 1;
`

export const Colophon = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
