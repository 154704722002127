import * as Styled from '../../styled/Magazine.styled'
import profileImage from '../assets/img/profile-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Heading5, Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const HeaderProfilePicture = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 3em .5em 3em">
          <Heading5.Regular color="white" textAlign="center">
            ENCABEZADO
          </Heading5.Regular>
        </TextFrame>
        <TextFrame marging="0 2em 0 2em">
          <Paragraph.Light color="white" textAlign="center">
            En esta sección vamos a agregar una foto de perfil haciendo clic en la imagen
            predeterminada.
          </Paragraph.Light>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={profileImage} />
    </Page>
  )
}
