import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { MadeWithLoveFor } from '../MadeWithLoveFor'
import { TitleHero } from '../TitleHero'
import * as Styled from './Header.styled'

export const Header: FC = () => {
  const navigate = useNavigate()
  return (
    <Styled.Header onClick={() => navigate('/curriculum')} margin="1em">
      <TitleHero>
        <b>CV FRIENDLY</b>
      </TitleHero>
      <MadeWithLoveFor name="you" />
    </Styled.Header>
  )
}
