export const noTrack = () => {
  let cookieName = 'gtm_internal_user'
  let cookieValue = 'internal'
  let expirationTime = 31104000
  expirationTime = expirationTime * 1000
  let date = new Date()
  let dateTimeNow = date.getTime()
  date.setTime(dateTimeNow + expirationTime)
  let utcDate = date.toUTCString()
  document.cookie = `${cookieName}=${cookieValue};SameSite=None;Secure;expires=${utcDate};path=/;domain=.${
    process.env.REACT_APP_BASE_URL?.split('//')[1]
  }`
}
