import * as Styled from './Text.styled'
import { TextProps } from './Text.model'
import { FC, useRef, useState, KeyboardEvent } from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import { fontTag } from '../../themes/CurriculumTheme/typography'
import { Menu } from '../Menu'

export const Text: FC<TextProps> = ({
  value,
  font,
  color,
  onChange,
  isSingleLine = true,
  setIsEditMode,
  isFormattable = true,
}) => {
  const text = useRef(value)
  const contentEditableRef = useRef<ContentEditable>(null)
  const [scroll, setScroll] = useState(0)
  const [isSelected, setIsSelected] = useState(false)
  const [width, setWidth] = useState(0)
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  const handleChange = (event: ContentEditableEvent) => {
    if (scroll < event.currentTarget.scrollWidth && scroll !== 0) {
      return
    }
    text.current = event.target.value
    onChange(text.current)
  }

  const handleFocus = () => {
    setTimeout(() => {
      setIsEditMode(true)
    }, 300)
  }
  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isSingleLine && e.key === 'Enter') {
      e.preventDefault()
      return
    }
    if (scroll < e.currentTarget.scrollWidth && scroll !== 0) {
      setScroll(e.currentTarget.scrollWidth - 1)
      e.preventDefault()
    } else {
      setScroll(e.currentTarget.scrollWidth)
    }
  }
  const handleSelect = () => {
    const selection = window.getSelection()
    if (selection && selection.toString() !== '') {
      const position = selection.getRangeAt(0).getBoundingClientRect()
      setLeft(position.left)
      setTop(position.top)
      setWidth(position.width)
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }

  const handlerPaste = (paste: string) => {
    const selection = window.getSelection()
    if (selection) {
      const newContent = document.createTextNode(paste)
      const range = selection.getRangeAt(0)
      range.deleteContents()
      range.insertNode(newContent)
      text.current = contentEditableRef.current?.el.current.innerHTML
    }
  }
  const handlerBlur = () => {
    setTimeout(() => {
      setIsEditMode(false)
      setIsSelected(false)
    }, 300)
  }

  return (
    <Styled.Text>
      {isSelected && isFormattable && (
        <Menu top={top} left={left} width={width} isSingleLine={isSingleLine} />
      )}
      <Styled.ContentEditableStyled
        $font={font}
        $color={color || 'black'}
        $isSingleLine={isSingleLine}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handlerBlur}
        onKeyDown={handleKeypress}
        onSelect={handleSelect}
        onPaste={(e) => {
          e.preventDefault()
          handlerPaste(e.clipboardData.getData('Text'))
        }}
        html={text.current}
        tagName={fontTag[font]}
        ref={contentEditableRef}
      />
    </Styled.Text>
  )
}
