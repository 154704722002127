import * as Styled from '../../styled/Magazine.styled'
import contactImage from '../assets/img/contact-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const HeaderContact = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <TextFrame marging="0 2em 0 2em">
          <Paragraph.Light color="white">
            Después del nombre y el puesto escribiremos un correo y número telefónico, ocultar los
            campos es tan simple como borrar la información.
          </Paragraph.Light>
        </TextFrame>
      </Styled.Introduction>
      <Styled.IntroductionImage src={contactImage} />
    </Page>
  )
}
