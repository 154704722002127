import * as Styled from '../../styled/Magazine.styled'
import skillsImage from '../assets/img/skills-image-0001@3x.png'
import { customColors } from '../customColors'
import { Page } from '../../components/Page'
import { Heading5, Italic, Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const Skills = () => {
  return (
    <Page color="coreSecondary400">
      <Styled.Introduction startColor={customColors.orange700} endColor={customColors.orange500}>
        <Styled.HorizontalRule marging="0 2em 1em 2em" />
        <TextFrame marging="0 2em 0 2em">
          <Heading5.Regular color="white" textAlign="center">
            HABILIDADES
          </Heading5.Regular>
        </TextFrame>
        <Styled.HorizontalRule marging="1em 2em 0 2em" />
      </Styled.Introduction>
      <Styled.IntroductionImage src={skillsImage} />
      <Styled.Content>
        <TextFrame marging="2em 2em 2em 2em">
          <Paragraph.Light>
            Aquí puedes agregar todas esas <Italic>skills</Italic>, habilidades que son dignas de
            presumir, ser reconocidas y aporten valor a la vacante a postularse. Doblar la lengua no
            es una de ellas XD
          </Paragraph.Light>
        </TextFrame>
      </Styled.Content>
    </Page>
  )
}
