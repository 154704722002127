import defaultData from './curriculum.data.json'
import { CurriculumData } from './Curriculum.model'

export const getCurriculum = () => {
  let data = defaultData as CurriculumData
  const item = window.localStorage.getItem('curriculum')
  if (item) {
    data = JSON.parse(item)
  }
  return data
}

export const setCurriculum = (curriculum: CurriculumData) => {
  window.localStorage.setItem('curriculum', JSON.stringify(curriculum))
}
