import { DefaultTheme } from 'styled-components'
import { neutralColors, corePrimaryColors, coreSecondaryColors } from '../colors'

const primaryColors = {
  primary050: '#f4f7fa',
  primary100: '#ebeef4',
  primary200: '#dfe6ee',
  primary300: '#b0bac5',
  primary400: '#3e5a83',
  primary500: '#8091a5',
  primary600: '#667587',
  primary700: '#3f4a56',
  primary800: '#313541',
  primary900: '#16181e',
}

const secondaryColors = {
  secondary050: '#f4f7fa',
  secondary100: '#ebeef4',
  secondary200: '#dfe6ee',
  secondary300: '#b0bac5',
  secondary400: '#2f4e7a',
  secondary500: '#8091a5',
  secondary600: '#667587',
  secondary700: '#3f4a56',
  secondary800: '#313541',
  secondary900: '#16181e',
}

export const colors: DefaultTheme['colors'] = {
  ...primaryColors,
  ...secondaryColors,
  ...neutralColors,
  ...corePrimaryColors,
  ...coreSecondaryColors,
}
