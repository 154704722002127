import * as Styled from '../../styled/Magazine.styled'
import toolMenuImage from '../assets/img/tool-menu-image-0001@3x.png'
import menuExampleImage from '../assets/img/menu-example-image-0001@3x.png'
import { Page } from '../../components/Page'
import { Paragraph, TextFrame } from '../../../../themes/typography.styled'

export const ToolsMenu = () => {
  return (
    <Page color="coreSecondary400">
      <TextFrame marging="0 0 2em 0" padding="2em 2em" color="white">
        <Paragraph.Light>
          Al seleccionar cualquier texto se muestra un menú contextual para asignar un estilo
          negritas, cursiva o tipo lista.
        </Paragraph.Light>
      </TextFrame>
      <Styled.IntroductionImage src={toolMenuImage} />
      <Styled.IntroductionImage src={menuExampleImage} />
    </Page>
  )
}
