import type { DefaultTheme } from 'styled-components'
import { colors } from './colors'
const display = 'Raleway'
const displayHero = 'Bebas Neue'
const content = 'Roboto'
const contentSerif = 'Abhaya Libre'

export const fontFamilies: DefaultTheme['fontFamilies'] = {
  display,
  displayHero,
  content,
  contentSerif,
}

export const typography: DefaultTheme['typography'] = {
  title1: {
    fontFamily: display,
    fontWeight: 300,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.005em',
    color: colors.gray900,
  },
  title2: {
    fontFamily: display,
    fontWeight: 300,
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0',
    color: colors.gray900,
  },
  title3: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.15rem',
    letterSpacing: '0.0025em',
    color: colors.gray900,
  },
  title4: {
    fontFamily: content,
    fontWeight: 700,
    fontSize: '.8rem',
    lineHeight: '.8rem',
    letterSpacing: '0.0025em',
    color: colors.gray900,
  },
  title5: {
    fontFamily: content,
    fontWeight: 700,
    fontSize: '.8rem',
    lineHeight: '.8rem',
    letterSpacing: '0.0025em',
    color: colors.gray900,
  },
  title6: {
    fontFamily: content,
    fontWeight: 700,
    fontSize: '.8rem',
    lineHeight: '.8rem',
    letterSpacing: '0.0025em',
    color: colors.gray900,
  },
  titleHero: {
    fontFamily: displayHero,
    fontWeight: 300,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.005em',
    color: colors.gray900,
  },
  subtitle: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  subtitle2: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  subtitle3: {
    fontFamily: display,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  paragraph: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.95rem',
    lineHeight: '1.25rem',
    letterSpacing: '0',
    color: colors.gray900,
  },
  paragraph1: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.85rem',
    lineHeight: '1rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  paragraph2: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.8rem',
    lineHeight: '1rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
  badge: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '0.75rem',
    lineHeight: '1.05rem',
    letterSpacing: '0.0025em',
    color: colors.white,
  },
  list: {
    fontFamily: content,
    fontWeight: 300,
    fontSize: '.90rem',
    lineHeight: '1rem',
    letterSpacing: '0.0015em',
    color: colors.gray900,
  },
}

export const fontTag: DefaultTheme['fontTag'] = {
  title1: 'h1',
  title2: 'h2',
  title3: 'h3',
  title4: 'h4',
  titleHero: 'h1',
  subtitle: 'p',
  subtitle2: 'p',
  subtitle3: 'p',
  paragraph: 'p',
  paragraph1: 'p',
  paragraph2: 'p',
  badge: 'p',
}
